@tailwind base;
@tailwind components;
@tailwind utilities;
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-slate-500;
}